<template>
	<div id="submenu">
		<template v-if="item.children">
			<el-submenu :index="'menu'+item.id">
				<template slot="title">
					<i v-if="item.meta.level==1" :class="item.meta.icon"></i>
					<div class="meunDiv" slot="title">
						{{item.meta.title}}
						<div v-if="checkMsg(item.id)" class="unRead">
							{{checkMsg(item.id)>99?'99+':checkMsg(item.id)}}
						</div>
					</div>
				</template>
				<submenu style="background: #fcfcfc;" v-for="children in item.children" :key="children.id" :item="children" :msgCountList="msgCountList"></submenu>
			</el-submenu>
		</template>
		<template v-else>
			<el-menu-item :index="'menu'+item.id" @click="toLink(item)">
				<i v-if="item.meta.level==1" :class="item.meta.icon"></i>
				<div class="meunDiv" slot="title">
					{{item.meta.title}}
					<div v-if="checkMsg(item.id)" class="unRead">
						{{checkMsg(item.id)>99?'99+':checkMsg(item.id)}}
					</div>
					<div v-if="item.id==2131&&totalUnreadCount" class="unRead">
						{{totalUnreadCount>99?'99+':totalUnreadCount}}
					</div>
				</div>
			</el-menu-item>
		</template>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		name: "submenu",
		data() {
			return {
				
			};
		},
		props:{
			item: {
			  type: Object,
			  required: true
			},
			msgCountList:{
				type: Array,
				default:()=>[]
			}
		},
		computed: {
			...mapGetters({
				totalUnreadCount: 'totalUnreadCount',
			}),
		},
		methods:{
			checkMsg(id){
				const count = this.msgCountList.find(e=>e.nav_id==id)
				if(count&&count.count>0){
					return count.count
				}else{
					return false
				}
			},
			toLink(item){
				let router = {path:item.path}
				if(item.meta.props){
					router.query = {props: item.meta.props}
				}
				this.$router.push(router)
			}
		}
	};
</script>

<style lang="less" scoped>
	.meunDiv{
		display: inline-block;
		position: relative;
		.unRead {
			position: absolute;
			left: calc(100% + 6px);
			top: 50%;
			margin-top: -7px;
			color: #ffffff;
			background-color: #F56C6C;
			padding: 0 4px;
			height: 14px;
			font-size: 12px;
			box-sizing: border-box;
			border-radius: 14px;
			font-weight: normal;
			display: flex;
			align-items: center;
		}
		&::selection{
			background: rgba(0, 0, 0, 0);
		}
		&::-moz-selection{
			background: rgba(0, 0, 0, 0);
		}
	}
</style>
